import { Select } from "@chakra-ui/react";
import { useDateFormatter } from "@react-aria/i18n";
import PropTypes from "prop-types";
import { useMemo } from "react";

/**
 * @typedef {{ min: number, max: number }} YearSelectorRange
 */

/**
 * @typedef Props
 * @property {object} state
 * @property {YearSelectorRange} [yearRange]
 * @property {import("react").Dispatch<import("react").SetStateAction<any>>} onChangeYear
 */
/**
 * @param {Props & import("@chakra-ui/react").SelectProps} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
function YearSelector(props) {
  const {
    state,
    yearRange = { min: -20, max: 20 },
    onChangeYear,
    ...otherProps
  } = props;
  let formatter = useDateFormatter({
    year: "numeric",
    timeZone: state.timeZone,
  });

  const years = useMemo(() => {
    let years = [];
    // Format 20 (default value in yearRange) years on each side of the current year according
    // to the current locale and calendar system.
    for (let i = yearRange.min; i <= yearRange.max; i++) {
      let date = state.focusedDate.add({ years: i });

      if (state.minValue && date.compare(state.minValue) < 0) {
        continue;
      }

      if (state.maxValue && date.compare(state.maxValue) > 0) {
        continue;
      }

      years.push({
        value: date,
        formatted: formatter.format(date.toDate(state.timeZone)),
      });
    }
    return years;
  }, [
    yearRange.min,
    yearRange.max,
    state.focusedDate,
    state.minValue,
    state.maxValue,
    state.timeZone,
    formatter,
  ]);

  let onChange = (e) => {
    let index = Number(e.target.value);
    let date = years[index].value;

    onChangeYear(date);

    state.setFocusedDate(date);
  };

  const value = useMemo(() => {
    // Find the index of the currently selected year
    const index = years.findIndex(
      (year) => 0 === year.value.compare(state.focusedDate),
    );

    return index !== -1 ? index : undefined;
  }, [years, state.focusedDate]);

  return (
    <Select
      {...otherProps}
      size="sm"
      aria-label="Year"
      onChange={onChange}
      value={value}
      w={90}>
      {years.map((year, i) => (
        // use the index as the value so we can retrieve the full
        // date object from the list in onChange. We cannot only
        // store the year number, because in some calendars, such
        // as the Japanese, the era may also change.
        <option key={i} value={i}>
          {year.formatted}
        </option>
      ))}
    </Select>
  );
}

export default YearSelector;

YearSelector.propTypes = {
  state: PropTypes.object.isRequired,
  onChangeYear: PropTypes.func.isRequired,
};

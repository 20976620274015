import {
  Alert,
  AlertDescription,
  Box,
  Flex,
  Grid,
  Link,
  Radio,
  Select,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import RadioGroupAdmin from "@raiden/library-ui/components/Form/RadioGroup";
import { InputAddress } from "@raiden/library-ui/components/Form/InputAddress";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import ObserverRHF from "@raiden/library-ui/components/ReactHookForm/ObserverRHF";
import {
  customersTypeOfPersonMessage,
  CUSTOMERS_FISCAL_VALUE_PRO,
  CUSTOMERS_TYPES_OF_PERSON_LIST,
} from "@raiden/library-ui/constants/customers";
import {
  usersAdminsGenderMessage,
  USERS_ADMINS_GENDER_LIST,
} from "@raiden/library-ui/constants/users/admins";
import getAdvisedTextFromMissingAttributes from "@raiden/library-ui/helpers/form/getAdvisedTextFromMissingAttributes";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import capitalize from "@splitfire-agency/raiden-library/dist/libraries/utils/capitalize";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useCallback } from "react";
import { RGrid } from "@raiden/library-ui/components/RGrid";

const REQUIRED_FIELDS = [
  "data.is_pro",
  "data.person_type",
  "data.fiscal_company",
  "data.fiscal_siren",
  "data.fiscal_number",
  "data.gender",
  "data.last_name",
  "data.first_name",
  "data.address_1",
  "data.zipcode",
  "data.city",
  "data.country_id",
  "data.birthdate",
];

/**
 * DAC7 form for customers (from the customer point of view)
 * @typedef {object} Props
 * @property {Array<import("@raiden/library-ui/types/Country").Country>} countries
 * @property {boolean} [withAlert]
 * @property {string[]} [requiredFields]
 * @property {string[]} [missingAttributes]
 * @property {boolean} [withCompanyName]
 */
/**
 * @param {Props} props
 */
export default function Dac7Form({
  countries,
  withAlert = true,
  requiredFields = REQUIRED_FIELDS,
  missingAttributes = [],
  withCompanyName = false,
}) {
  const intl = useIntl();

  /** @type {import("react-hook-form").UseFormReturn<import("@raiden/library-ui/types/Customer").CustomerUpdatePersonalInformationsDataFormValues | import("@raiden/library-ui/types/Customer").CustomerFiscalDataFormValues>} */
  const { control, setValue } = useFormContext();

  const translate = useTranslate();

  const color = useColorModeValue("gray.100", "gray.600");

  const handleAutoFill = useCallback(
    /** @type {import("@raiden/library-ui/components/Form/InputAddress").InputAddressOnAutoFillCallback} */
    ({ address, city, zipcode }) => {
      address && setValue("data.address_1", address, { shouldDirty: true });
      city && setValue("data.city", city, { shouldDirty: true });
      zipcode &&
        setValue("data.zipcode", String(zipcode), { shouldDirty: true });
    },
    [setValue],
  );

  return (
    <>
      {withAlert && (
        <Alert status="info" mb="1rem">
          <AlertDescription>
            <FormattedMessage
              defaultMessage="Nouvelles obligations déclaratives, directive DAC7.{br}Afin de nous conformer à de nouvelles obligations déclaratives pour la réservation en ligne, nous sommes dans l'obligation de collecter vos informations fiscales, retrouvez plus d'informations {link}."
              values={{
                br: <br />,
                link: (
                  <Link
                    textDecoration="underline"
                    href="https://wiki.clevacances.com/nouvelles-obligations-declaratives-directive-dac7/"
                    target="_blank"
                    rel="noopener noreferrer">
                    {intl.formatMessage({
                      defaultMessage: "en cliquant sur ce lien",
                    })}
                  </Link>
                ),
              }}
            />
          </AlertDescription>
        </Alert>
      )}

      <Stack spacing="1rem">
        <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr" }} gap="1rem">
          <FormControlRHF
            control={control}
            rules={{
              required: requiredFields.includes("data.is_pro"),
            }}
            name="data.is_pro"
            label={intl.formatMessage({
              defaultMessage: "Êtes-vous un hébergeur professionnel ?",
            })}
            renderWithFormControl={(field) => (
              <Flex gap=".75rem" alignItems="center" mb=".5rem">
                <RadioGroupAdmin {...field} width="full">
                  <Grid templateColumns="1fr 1fr" gap="1rem" w="full">
                    <Box background={color} borderRadius="6px">
                      <Radio bg="white" value="1" w="full" p=".5rem">
                        {intl.formatMessage({
                          defaultMessage: "Oui",
                        })}
                      </Radio>
                    </Box>

                    <Box background={color} borderRadius="6px">
                      <Radio bg="white" value="0" w="full" p=".5rem">
                        {intl.formatMessage({
                          defaultMessage: "Non",
                        })}
                      </Radio>
                    </Box>
                  </Grid>
                </RadioGroupAdmin>
              </Flex>
            )}
          />

          <ObserverRHF
            names={["data.is_pro"]}
            render={({ values: [isPro] }) => (
              <>
                {isPro === "1" && (
                  <>
                    <FormControlRHF
                      control={control}
                      rules={{
                        required: requiredFields.includes("data.person_type"),
                      }}
                      label={intl.formatMessage({
                        defaultMessage: "Type de professionnel",
                      })}
                      name="data.person_type"
                      renderWithFormControl={(field) => (
                        <Select {...field}>
                          <option value="">-</option>

                          {CUSTOMERS_TYPES_OF_PERSON_LIST.map((type) => (
                            <option key={type.id} value={type.id}>
                              {intl.formatMessage(
                                customersTypeOfPersonMessage,
                                {
                                  type: type.id,
                                },
                              )}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                  </>
                )}
              </>
            )}
          />
        </Grid>

        <ObserverRHF
          names={["data.is_pro", "data.country_id"]}
          render={({ values: [isPro, countryId] }) => (
            <>
              {isPro === "1" && (
                <>
                  <Grid
                    templateColumns={{ base: "1fr", lg: "1fr 1fr" }}
                    columnGap="1rem">
                    <FormControlRHF
                      control={control}
                      label={intl.formatMessage({
                        defaultMessage: "Raison sociale ou nom de société",
                      })}
                      rules={{
                        required: requiredFields.includes(
                          "data.fiscal_company",
                        ),
                      }}
                      name="data.fiscal_company"
                    />

                    <FormControlRHF
                      control={control}
                      label={intl.formatMessage({
                        defaultMessage: "N° de SIREN",
                      })}
                      helperText={intl.formatMessage({
                        defaultMessage:
                          "9 chiffres, pas d’espace ni autres caractères.",
                      })}
                      rules={{
                        required: requiredFields.includes("data.fiscal_siren"),
                      }}
                      name="data.fiscal_siren"
                    />

                    {withCompanyName && (
                      <FormControlRHF
                        control={control}
                        label={intl.formatMessage({
                          defaultMessage: "Nom commercial",
                        })}
                        rules={{
                          required:
                            requiredFields.includes("data.company_name"),
                        }}
                        name="data.company_name"
                      />
                    )}
                  </Grid>
                </>
              )}

              <Grid
                templateColumns={{ base: "1fr", lg: "1fr 1fr" }}
                gap="1rem"
                w="full">
                <Grid
                  templateColumns={{ base: "1fr", lg: ".5fr 1fr" }}
                  gap="1rem">
                  <FormControlRHF
                    control={control}
                    label={intl.formatMessage({
                      defaultMessage: "Civilité",
                    })}
                    advisedText={getAdvisedTextFromMissingAttributes({
                      field: "gender",
                      missingAttributes,
                      intl,
                    })}
                    rules={{
                      required: requiredFields.includes("data.gender"),
                    }}
                    name="data.gender"
                    renderWithFormControl={(field) => (
                      <Select {...field}>
                        <option value="">{"---"}</option>

                        {USERS_ADMINS_GENDER_LIST.map((item) => (
                          <option key={item.id} value={item.id}>
                            {capitalize(
                              intl.formatMessage(usersAdminsGenderMessage, {
                                gender: item.id,
                              }),
                            )}
                          </option>
                        ))}
                      </Select>
                    )}
                  />

                  <FormControlRHF
                    control={control}
                    advisedText={getAdvisedTextFromMissingAttributes({
                      field: "last_name",
                      missingAttributes,
                      intl,
                    })}
                    name="data.last_name"
                    label={intl.formatMessage(
                      {
                        defaultMessage:
                          "{isPro, select, true {Nom du gérant} other {Nom}}",
                      },
                      { isPro: isPro === CUSTOMERS_FISCAL_VALUE_PRO },
                    )}
                    rules={{
                      required: requiredFields.includes("data.last_name"),
                    }}
                  />
                </Grid>

                <FormControlRHF
                  control={control}
                  advisedText={getAdvisedTextFromMissingAttributes({
                    field: "first_name",
                    missingAttributes,
                    intl,
                  })}
                  name="data.first_name"
                  label={intl.formatMessage(
                    {
                      defaultMessage:
                        "{isPro, select, true {Prénom du gérant} other {Prénom}}",
                    },
                    { isPro: isPro === CUSTOMERS_FISCAL_VALUE_PRO },
                  )}
                  rules={{
                    required: requiredFields.includes("data.first_name"),
                  }}
                />
              </Grid>

              <RGrid>
                <FormControlRHF
                  control={control}
                  advisedText={getAdvisedTextFromMissingAttributes({
                    field: "address_1",
                    missingAttributes,
                    intl,
                  })}
                  name="data.address_1"
                  label={intl.formatMessage({
                    defaultMessage: "Adresse 1 (Bâtiment, ...)",
                  })}
                  rules={{
                    required: requiredFields.includes("data.address_1"),
                  }}
                  renderWithFormControl={(field) => (
                    <InputAddress
                      {...field}
                      onAutoFill={handleAutoFill}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Adresse 1 (Bâtiment, ...)",
                      })}
                    />
                  )}
                />
              </RGrid>

              <FormControlRHF
                control={control}
                name="data.address_2"
                label={intl.formatMessage({
                  defaultMessage: "Adresse 2 (Rue, Chemin, ...)",
                })}
              />

              <Grid
                templateColumns={{ base: "1fr", lg: "1fr 1fr 1fr" }}
                gap="1rem"
                w="full">
                <Box>
                  <FormControlRHF
                    control={control}
                    advisedText={getAdvisedTextFromMissingAttributes({
                      field: "zipcode",
                      missingAttributes,
                      intl,
                    })}
                    name="data.zipcode"
                    rules={{
                      required: requiredFields.includes("data.zipcode"),
                    }}
                    label={intl.formatMessage({
                      defaultMessage: "Code postal",
                    })}
                  />
                </Box>

                <Box>
                  <FormControlRHF
                    control={control}
                    name="data.city"
                    advisedText={getAdvisedTextFromMissingAttributes({
                      field: "city",
                      missingAttributes,
                      intl,
                    })}
                    label={intl.formatMessage({ defaultMessage: "Commune" })}
                    rules={{
                      required: requiredFields.includes("data.city"),
                    }}
                  />
                </Box>

                <Box>
                  <FormControlRHF
                    control={control}
                    name="data.country_id"
                    label={intl.formatMessage({
                      defaultMessage: "Pays",
                    })}
                    advisedText={getAdvisedTextFromMissingAttributes({
                      field: "country_id",
                      missingAttributes,
                      intl,
                    })}
                    rules={{
                      required: requiredFields.includes("data.country_id"),
                    }}
                    renderWithFormControl={(field) => (
                      <Select {...field}>
                        <option value="">-</option>

                        {countries?.map?.((country) => (
                          <option value={country.id} key={country.id}>
                            {translate(country.name)}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                </Box>
              </Grid>

              {isPro === "0" && (
                <Grid
                  templateColumns={{ base: "1fr", lg: "1fr 1fr" }}
                  gap="1rem">
                  <FormControlRHF
                    control={control}
                    rules={{
                      required: requiredFields.includes("data.birthdate"),
                    }}
                    type="datetime-picker"
                    name="data.birthdate"
                    label={intl.formatMessage({
                      defaultMessage: "Date de naissance",
                    })}
                    inputProps={{
                      minWidth: "100%",
                      enableKeyboardInput: true,
                      minValue: dayjs()
                        .subtract(100, "year")
                        .format("YYYY-MM-DD"),
                      maxValue: dayjs()
                        .subtract(18, "year")
                        .format("YYYY-MM-DD"),
                      yearRange: {
                        min: -100,
                        max: 20,
                      },
                    }}
                  />

                  {countries.find((country) => country.iso_code === "FR")
                    ?.id === Number(countryId) && (
                    <FormControlRHF
                      control={control}
                      label={intl.formatMessage({
                        defaultMessage: "Numéro fiscal",
                      })}
                      helperText={intl.formatMessage({
                        defaultMessage:
                          "13 chiffres, pas d’espace ni autres caractères.",
                      })}
                      rules={{
                        required: requiredFields.includes("data.fiscal_number"),
                      }}
                      name="data.fiscal_number"
                    />
                  )}
                </Grid>
              )}
            </>
          )}
        />
      </Stack>
    </>
  );
}

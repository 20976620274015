/**
 * @typedef {object} hotel
 * @property {import("../../../types/common/TranslatedField").TranslatedField | string | null | undefined} slug
 * @property {number} id
 */

/**
 * @param {object} params
 * @param {hotel} [params.hotel]
 * @param {string} [params.locale]
 * @param {string} [params.defaultLocale]
 * @returns {string}
 */
export function getHotelSlug({ hotel, locale, defaultLocale }) {
  /** @type {Array<number | string>} */
  const hotelSlug = [];
  const slug = hotel?.slug;
  const _locale = locale || defaultLocale;

  if (hotel?.id) {
    hotelSlug.push(hotel.id);
  }

  if (slug) {
    if (typeof slug === "string") {
      hotelSlug.push(slug);
    } else if (typeof slug === "object") {
      const _slug = _locale ? slug?.[_locale] : null;
      if (_slug) {
        hotelSlug.push(_slug);
      }
    }
  }

  return hotelSlug.join("-");
}

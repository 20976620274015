import { Link, Stack, Text } from "@chakra-ui/react";
import useLocale from "@raiden/library-ui/hooks/useLocale";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Place } from "./Place";
import NextLink from "next/link";
import { getHotelSlug } from "@raiden/library-ui/helpers/hotels/getSlug";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Place.js").Place | null} place
 * @property {string} term
 * @property {import("@raiden/library-ui/types/PlaceAutocomplete.js").PlaceAutocomplete[]} placesAutocomplete
 * @property {import(".").PlacesSearchGetPlaceCallback} getPlace
 * @property {import(".").PlacesSearchSetRegionCode} setRegionCode
 * @property {import(".").PlacesSearchSetDepartmentCode} setDepartmentCode
 * @property {import(".").PlacesSearchSetLegacyRegionCode} setLegacyRegionCode
 */

/**
 * @param {Props} props
 */
export function PlacesSearchResults({
  place,
  term,
  placesAutocomplete,
  getPlace,
  setRegionCode,
  setDepartmentCode,
  setLegacyRegionCode,
}) {
  const { locale, defaultLocale } = useLocale();

  const cities = useMemo(() => {
    return placesAutocomplete.filter((place) => place.type === "city") ?? [];
  }, [placesAutocomplete]);

  const departments = useMemo(() => {
    return (
      placesAutocomplete.filter((place) => place.type === "department") ?? []
    );
  }, [placesAutocomplete]);

  const regions = useMemo(() => {
    return (
      placesAutocomplete.filter(
        (place) => place.type === "legacy_region" || place.type === "region",
      ) ?? []
    );
  }, [placesAutocomplete]);

  const placesToVisit = useMemo(() => {
    return (
      placesAutocomplete.filter(
        (place) =>
          place.type === "point_of_interest" ||
          place.type === "tourist_attraction",
      ) ?? []
    );
  }, [placesAutocomplete]);

  const hotels = useMemo(() => {
    return placesAutocomplete.filter((place) => place.type === "hotel") ?? [];
  }, [placesAutocomplete]);

  const otherPlaces = useMemo(() => {
    return (
      placesAutocomplete.filter(
        (place) =>
          [
            "region",
            "legacy_region",
            "department",
            "city",
            "point_of_interest",
            "tourist_attraction",
            "hotel",
          ].includes(place.type) === false,
      ) ?? []
    );
  }, [placesAutocomplete]);

  const handleClickPlace = useCallback(
    /** @type {import("./Place").PlaceAutocompleteOnClickCallback} */
    ({ placeAutocomplete }) => {
      if (placeAutocomplete.type === "region" && placeAutocomplete.code) {
        setRegionCode(placeAutocomplete.code);
      } else if (
        placeAutocomplete.type === "department" &&
        placeAutocomplete.code
      ) {
        setDepartmentCode(placeAutocomplete.code);
      } else if (
        placeAutocomplete.type === "legacy_region" &&
        placeAutocomplete.code
      ) {
        setLegacyRegionCode(placeAutocomplete.code);
      } else {
        getPlace({ placeId: placeAutocomplete.id.toString() });
      }
    },
    [setRegionCode, setDepartmentCode, setLegacyRegionCode, getPlace],
  );

  return (
    <>
      <Stack spacing="0.75rem">
        {regions.length > 0 && (
          <>
            <Text variant="text-input">
              <FormattedMessage defaultMessage="Régions" />
            </Text>

            <Stack spacing="0.25rem">
              {regions.map((placeAutocomplete, index) => (
                <Place
                  id={`places-search-result-${index}`}
                  place={place}
                  placeAutocomplete={placeAutocomplete}
                  term={term}
                  onClick={handleClickPlace}
                  key={placeAutocomplete.id}
                />
              ))}
            </Stack>
          </>
        )}

        {departments.length > 0 && (
          <>
            <Text variant="text-input">
              <FormattedMessage defaultMessage="Départements" />
            </Text>

            <Stack spacing="0.25rem">
              {departments.map((placeAutocomplete, index) => (
                <Place
                  id={`places-search-result-${index + regions.length}`}
                  place={place}
                  placeAutocomplete={placeAutocomplete}
                  term={term}
                  onClick={handleClickPlace}
                  key={placeAutocomplete.id}
                />
              ))}
            </Stack>
          </>
        )}

        {cities.length > 0 && (
          <>
            <Text variant="text-input">
              <FormattedMessage defaultMessage="Villes" />
            </Text>

            <Stack spacing="0.25rem">
              {cities.map((placeAutocomplete, index) => (
                <Place
                  id={`places-search-result-${
                    index + departments.length + regions.length
                  }`}
                  place={place}
                  placeAutocomplete={placeAutocomplete}
                  term={term}
                  onClick={handleClickPlace}
                  key={placeAutocomplete.id}
                />
              ))}
            </Stack>
          </>
        )}

        {placesToVisit.length > 0 && (
          <>
            <Text variant="text-input">
              <FormattedMessage defaultMessage="À visiter" />
            </Text>

            <Stack spacing="0.25rem">
              {placesToVisit.map((placeAutocomplete, index) => (
                <Place
                  id={`places-search-result-${
                    index + cities.length + departments.length + regions.length
                  }`}
                  place={place}
                  placeAutocomplete={placeAutocomplete}
                  term={term}
                  onClick={handleClickPlace}
                  key={placeAutocomplete.id}
                />
              ))}
            </Stack>
          </>
        )}

        {hotels.length > 0 && (
          <>
            <Text variant="text-input">
              <FormattedMessage defaultMessage="Hébergements" />
            </Text>

            <Stack spacing="0.25rem">
              {hotels.map((placeAutocomplete, index) => (
                <NextLink
                  key={placeAutocomplete.id}
                  href={generateFrontPath({
                    id: "@front.hotels.view",
                    parameters: {
                      hotelSlug: getHotelSlug({
                        hotel: {
                          id: Number(placeAutocomplete.id),
                          slug: placeAutocomplete.slug,
                        },
                        locale,
                        defaultLocale,
                      }),
                    },
                  })}
                  passHref>
                  <Link _hover={{ textDecoration: "none" }}>
                    <Place
                      id={`places-search-result-${
                        index +
                        cities.length +
                        departments.length +
                        placesToVisit.length +
                        regions.length
                      }`}
                      place={place}
                      placeAutocomplete={placeAutocomplete}
                      term={term}
                    />
                  </Link>
                </NextLink>
              ))}
            </Stack>
          </>
        )}

        {otherPlaces.length > 0 && (
          <>
            <Text variant="text-input">
              <FormattedMessage defaultMessage="Autres" />
            </Text>

            <Stack spacing="0.25rem">
              {otherPlaces.map((placeAutocomplete, index) => (
                <Place
                  id={`places-search-result-${
                    index +
                    cities.length +
                    departments.length +
                    placesToVisit.length +
                    regions.length +
                    hotels.length
                  }`}
                  place={place}
                  placeAutocomplete={placeAutocomplete}
                  term={term}
                  onClick={handleClickPlace}
                  key={placeAutocomplete.id}
                />
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
}

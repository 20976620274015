// deps
import { useIntl } from "react-intl";

// components
import Calendar from "./Calendar";
import TimeField from "./TimeField";
import PropTypes from "prop-types";

/**
 * @typedef Props
 * @property {import("@react-stately/datepicker").DatePickerState} state
 * @property {object} calendarProps
 * @property {boolean} [shouldRenderClearButton]
 * @property {boolean} [shouldRenderTodayButton]
 * @property {boolean} [shouldCloseOnDaySelect]
 * @property {(event: any) => void} onChange
 * @property {import("./YearSelector").YearSelectorRange} [yearRange]
 */
/**
 * @returns {import("react").FunctionComponentElement<Props>}
 */

/**
 * @param {Props} props
 * @returns {import("react").ReactElement}
 */
function CalendarDateTime(props) {
  const intl = useIntl();

  return (
    <>
      <Calendar
        {...props.calendarProps}
        state={props.state}
        setOpen={props.state.setOpen}
        onChange={props.onChange}
        shouldCloseOnDaySelect={props.shouldCloseOnDaySelect}
        shouldRenderClearButton={props.shouldRenderClearButton}
        shouldRenderTodayButton={props.shouldRenderTodayButton}
        yearRange={props.yearRange}
      />
      {["hour", "minute", "second"].includes(props.state.granularity) && (
        <TimeField
          label={intl.formatMessage({
            defaultMessage: "Heure",
          })}
          value={props.state.timeValue}
          onChange={props.state.setTimeValue}
        />
      )}
    </>
  );
}

CalendarDateTime.propTypes = {
  state: PropTypes.object,
  calendarProps: PropTypes.object,
  shouldRenderClearButton: PropTypes.bool,
  shouldRenderTodayButton: PropTypes.bool,
  shouldCloseOnDaySelect: PropTypes.bool,
};

export default CalendarDateTime;

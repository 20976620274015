import nextRedirect from "../../../helpers/next/redirect";
import { nextGetUri } from "../../../helpers/next";
import generateFrontPath from "../../../libraries/utils/generateFrontPath";

// constants
import browser from "@splitfire-agency/raiden-library/dist/constants/browser";

// deps
import { PHASE_PRODUCTION_BUILD } from "next/constants";

// hooks
import { serverApiFetcher } from "../../../hooks/useApiFetcher";

// constants
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import { REDIRECTIONS_URLS_TIME_VALUE_PERMANENT } from "../../../constants/redirections/urls";
import { DEFAULT_ENVIRONMENT_ID } from "../../../constants/environments";
import generateFrontUrl from "../../../libraries/utils/generateFrontUrl";

const PUBLIC_FILE = /\.(.*)$/;

/**
 * @param {import("@splitfire-agency/raiden-library/dist/libraries/Uri").default} baseUri
 * @param {import("next").NextPageContext} [context]
 * @returns {boolean}
 */
function shouldTestUrl(baseUri, context) {
  if (
    context &&
    process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD &&
    !browser
  ) {
    // uniquement en cas de 404
    if (context.res?.statusCode === 404) {
      // on génére l'uri
      const uri = nextGetUri({ req: context?.req });
      if (uri) {
        const pathname = uri.getPath();
        // on exclut de notre test les routes vers /api et les fichiers publiques ainsi que de _next
        if (
          pathname.startsWith("/_next") ||
          pathname.includes("/api/") ||
          PUBLIC_FILE.test(pathname)
        ) {
          return false;
        }

        // on vérifie si l'host est le même que celui de l'uri
        if (baseUri.getHost() === uri.getHost()) {
          // on vérifie si l'uri ne commence pas par /signin ou /account
          return (
            !uri
              .getPath()
              .startsWith(generateFrontPath({ id: "@front.signin" })) ||
            !uri
              .getPath()
              .startsWith(generateFrontPath({ id: "@front.account" }))
          );
        }
      }
    }
  }
  return false;
}

/**
 * @param {string} url
 * @returns  {string}
 */
function cleanUrl(url) {
  const urlObj = new URL(url);
  const params = urlObj.searchParams;

  // Remove the `_gl` query parameter
  params.delete("_gl");

  // Reconstruct the URL without the `_gl` query parameter
  urlObj.search = params.toString();

  return decodeURIComponent(urlObj.toString()).replace(/'/g, "");
}

/**
 * @param {object} param0
 * @param {import("next").NextPageContext} [param0.nextPageContext]
 * @param {import("@splitfire-agency/raiden-library/dist/libraries/Uri").default} param0.baseUri
 * @param {string} [param0.locale]
 * @returns {Promise<void>}
 */
export default async function nextGetRedirectionsUrls({
  nextPageContext,
  baseUri,
  locale,
}) {
  if (shouldTestUrl(baseUri, nextPageContext)) {
    const uri = nextGetUri({ req: nextPageContext?.req });
    let initialPath = `/${locale}${uri.getPath()}`;
    if (process.env.NEXT_PUBLIC_FRONT_BASE_PATH) {
      initialPath = `${process.env.NEXT_PUBLIC_FRONT_BASE_PATH}${initialPath}`;
    }
    const url = uri.setPath(initialPath).toString();

    const redirectionsUrlsData = await serverApiFetcher(
      generateApiUrl({
        id: "@api.redirections.urls.hit",
      }),
      {
        method: "POST",
        body: {
          source_url: cleanUrl(url),
          environment_id: DEFAULT_ENVIRONMENT_ID,
        },
      },
      {
        origin: baseUri.toString(),
        locale,
      },
    ).catch(function (tempError) {
      // on ne throw pas, on ne veut pas que l'app affiche une erreur
      console.error(JSON.stringify(tempError), "tempError");
    });

    /** @type {import("../../../types/redirection/Url").RedirectionUrl} */
    const redirectionsUrls = redirectionsUrlsData?.data;

    if (nextPageContext?.res) {
      if (redirectionsUrls) {
        await nextRedirect({
          code:
            redirectionsUrls.redirection_type ===
            REDIRECTIONS_URLS_TIME_VALUE_PERMANENT
              ? 301
              : 302,
          redirectionUri: redirectionsUrls.target_url,
          res: nextPageContext?.res,
        });
      } else {
        // cas particulier pour les urls qui se terminent par /location-liste/* mais qui ne sont pas redirigées par l'api
        const locationList = /location-liste(?:[/?]|$)/;
        if (locationList.test(url)) {
          await nextRedirect({
            code: 301,
            redirectionUri: generateFrontUrl({
              id: "@front.search",
            }),
            res: nextPageContext?.res,
          });
        }
      }
    }
  }
}

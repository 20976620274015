import { useCallback } from "react";
import { TextWithHighlight } from "@raiden/library-ui/components/TextWithHighlight";

const HIGHLIGHT_STYLE = {
  backgroundColor: "brandPrimary.600",
  color: "#fff",
};

/**
 * @callback PlaceAutocompleteOnClickCallback
 * @param {{ placeAutocomplete: import("@raiden/library-ui/types/PlaceAutocomplete").PlaceAutocomplete }} params
 * @returns {void}
 */

/**
 * @typedef {object} Props
 * @property {string} id
 * @property {import("@raiden/library-ui/types/Place.js").Place | null} place
 * @property {import("@raiden/library-ui/types/PlaceAutocomplete").PlaceAutocomplete} placeAutocomplete
 * @property {string} term
 * @property {PlaceAutocompleteOnClickCallback} [onClick]
 */
/**
 * @param {Props} props
 */
export function Place({ id, place, placeAutocomplete, term, onClick }) {
  const isSelected = place?.id === placeAutocomplete.id;

  const handleClick = useCallback(() => {
    !isSelected && onClick?.({ placeAutocomplete });
  }, [isSelected, onClick, placeAutocomplete]);

  return (
    <TextWithHighlight
      id={id}
      tabIndex={0}
      text={placeAutocomplete.name}
      keywords={term}
      onClick={handleClick}
      py="0.125rem"
      px="0.625rem"
      borderRadius="10px"
      cursor="pointer"
      onKeyDown={(event) => {
        if (event.key === "Enter" || event.key === " ") {
          handleClick();
          event.preventDefault();
          event.stopPropagation();
        }
      }}
      {...(isSelected ? HIGHLIGHT_STYLE : {})}
      _hover={HIGHLIGHT_STYLE}
      key={placeAutocomplete.id}
    />
  );
}
